import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import StyledHero from './Hero.styled';
import HeroText from './HeroText/HeroText';

const query = graphql`
  query {
    file(name: { eq: "window" }) {
      childImageSharp {
        fluid(maxWidth: 554) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);

  return (
    <StyledHero>
      <HeroText />
      <Image
        className="hero-image"
        fluid={data.file.childImageSharp.fluid}
        rel="preload"
      />
    </StyledHero>
  );
};

export default Hero;
