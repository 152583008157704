import React from 'react';
import styled from 'styled-components';
import Headline from '../../Headline/Headline';

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;

  .producers__description {
    &__headline {
      /* text-align: right; */
    }

    &__paragraph {
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
    }
  }
`;

const Description = () => (
  <StyledDescription className="producers__description">
    <Headline className="producers__description__headline" align="right">
      Nasi producenci
    </Headline>
    <p className="producers__description__paragraph">
      Nasza firma zajmuje się sprzedażą nie tylko okien!
      <br />
      <br /> Współpracujemy z wieloma firmami, takimi jak: Drutex, KMT, Wikęd,
      Doorsy, Erkado, DRE, Pol-Skone, Vertex, Portos. <br />
      <br /> Kliknięcie na logo danej firmy automatycznie przekieruje do
      aktualnego katalogu.
    </p>
  </StyledDescription>
);

export default Description;
