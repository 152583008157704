import styled from 'styled-components';
import { device } from '../../style/theme';

export const StyledBackgroundProducers = styled.section`
  background: ${({ theme }) => theme.colors.secondary};
`;

export const StyledProducers = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;

    .producers {
      &__wrapper {
        width: 40%;
      }

      &__description {
        width: 30%;
      }
    }
  }
`;
