import React from 'react';
import propTypes from 'prop-types';
import HamburgerButton from '../HamburgerButton/HumburgerButton';
import PhoneInfo from '../PhoneInfo/PhoneInfo';
import StyledSmallNav from './SmallNav.styled';

const SmallNav = ({ isMenuActive, setMenuActive }) => (
  <StyledSmallNav>
    <HamburgerButton
      onClick={() => setMenuActive(!isMenuActive)}
      isMenuActive={isMenuActive}
    />
    <PhoneInfo />
  </StyledSmallNav>
);

SmallNav.propTypes = {
  isMenuActive: propTypes.bool.isRequired,
  setMenuActive: propTypes.func.isRequired,
};

export default SmallNav;
