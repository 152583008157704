import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import CheckIcon from '../../../../assets/icons/small-check.svg';
import { device } from '../../../../style/theme';

const StyledTile = styled.div`
  border: solid 1px;
  padding: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 266px;
  height: 330px;
  text-align: center;
  font-size: 2.1rem;
  position: relative;

  margin-bottom: 37px;

  @media ${device.tablet} {
    margin-bottom: 0;
    margin-right: 37px;
  }

  .tile {
    &__icon {
      margin-bottom: 20px;
    }

    &__check-icon {
      background: ${({ theme }) => theme.colors.secondary};
      border-radius: 45px;
      width: 72px;
      height: 72px;
      position: absolute;
      left: calc(50% - 36px);
      border: solid 2px black;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -52px;
      z-index: 1;

      @media ${device.tablet} {
        top: calc(50% - 36px);
        right: -52px;
        bottom: auto;
        left: auto;
      }

      svg {
        fill: ${({ theme }) => theme.colors.additional};
      }
    }
  }
`;

const Tile = ({ children, text, withCheck }) => (
  <StyledTile className="tile">
    {children}
    <span className="tile__text">{text}</span>
    {withCheck && (
      <div className="tile__check-icon">
        <CheckIcon />
      </div>
    )}
  </StyledTile>
);

Tile.propTypes = {
  children: propTypes.oneOfType([
    propTypes.node,
    propTypes.shape(propTypes.node),
  ]).isRequired,
  text: propTypes.string.isRequired,
  withCheck: propTypes.bool,
};

Tile.defaultProps = {
  withCheck: false,
};

export default Tile;
