import React from 'react';
import Headline from '../Headline/Headline';
import { StyledBackgroundContact, StyledContact } from './Contact.styled';
import Informations from './Informations/Informations';

const Contact = () => (
  <StyledBackgroundContact id="contact">
    <StyledContact className="container contact">
      <Headline align="left" className="contact__headline">
        Skontaktuj się z nami
      </Headline>
      <Informations />
    </StyledContact>
  </StyledBackgroundContact>
);

export default Contact;
