import React from 'react';
import propTypes from 'prop-types';
import StyledHamburgerButton from './HamburgerButton.styled';

const HamburgerButton = ({ onClick, isMenuActive }) => (
  <StyledHamburgerButton
    className="header__menu-button"
    onClick={onClick}
    isMenuActive={isMenuActive}
  >
    <div className="header__menu-button__divs">
      <div />
      <div />
      <div />
    </div>
  </StyledHamburgerButton>
);

HamburgerButton.propTypes = {
  onClick: propTypes.func.isRequired,
  isMenuActive: propTypes.bool.isRequired,
};

export default HamburgerButton;
