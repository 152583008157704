import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  align-items: center;
  margin: 50px 0;
`;

const query = graphql`
  {
    allFile(
      filter: {
        name: { regex: "/^[^@]*$/" }
        absolutePath: { regex: "/producers/" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;

const Wrapper = () => {
  const data = useStaticQuery(query);

  return (
    <StyledWrapper className="producers__wrapper">
      {data.allFile.edges.map(producer => {
        const {
          node: { name },
        } = producer;

        let url;

        switch (name) {
          case 'kmt':
            url =
              'https://kmt.com.pl/application/files/2716/1734/7760/Katalog_2021_I_KMT_Stal.pdf';
            break;
          case 'wiked':
            url =
              'https://wiked.pl/wp-content/uploads/2020/09/WIKED_JESIEN_2020_web.pdf';
            break;
          case 'doorsy':
            url =
              'https://www.doorsy.pl/files/download/15632763161367798949.pdf';
            break;
          case 'erkado':
            url = 'https://erkado.pl/wp-content/uploads/2020/09/dw_pl.pdf';
            break;
          case 'dre':
            url =
              'https://dre.pl/files/Katalog-Inewstycyjny-2020-Drzwi-wejściowe-do-mieszkań-edycja-2-C.pdf';
            break;
          case 'polskone':
            url =
              'https://www.pol-skone.pl/file/custom/katalog-lepsze-wnetrze-2020-i-pl.pdf';
            break;
          case 'vertex':
            url =
              'https://vertex.pl/wp-content/uploads/Vertex-deco-katalog.pdf';
            break;
          case 'portos':
            url = 'https://www.portosrolety.pl/download/PORTOS_KATALOG.pdf';
            break;
          case 'drutex':
            url =
              'https://www.drutex.pl/assets/files/pliki_do_pobrania/ulotki/pl/Katalog_Drutex_PL.pdf';
            break;
          default:
            break;
        }

        return (
          <a href={url} key={producer.node.id}>
            <GatsbyImage
              alt={producer.node.name}
              fluid={producer.node.childImageSharp.fluid}
            />
          </a>
        );
      })}
    </StyledWrapper>
  );
};

export default Wrapper;
