import React from 'react';
import styled from 'styled-components';
import LogoImage from '../../assets/icons/Logo.svg';

const StyledBackgroundFooter = styled.footer`
  background: #262626;
  color: ${({ theme }) => theme.colors.secondary};
`;

const StyledFooter = styled.footer`
  padding: 150px 0 75px;

  .footer {
    &__wrapper {
      display: flex;
      flex-direction: column;

      &__logo {
        width: 315px;
      }
    }
  }
`;

const Footer = () => (
  <StyledBackgroundFooter>
    <StyledFooter className="container">
      <div className="footer__wrapper">
        <LogoImage className="footer__wrapper__logo" />
        <span>Schol-Bud 2021</span>
        <span>Wszelkie prawa zastrzeżone</span>
        <span>Designed and created by Damian Kalka</span>
      </div>
    </StyledFooter>
  </StyledBackgroundFooter>
);

export default Footer;
