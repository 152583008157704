import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledSmallNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    justify-content: flex-end;
  }
`;

export default StyledSmallNav;
