import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import Headline from '../Headline/Headline';
import {
  StyledBackgroundMontage,
  StyledDescription,
  StyledMontage,
} from './Montage.styled';
import Tiles from './Tiles/Tiles';

const query = graphql`
  query {
    file(name: { eq: "montage" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const Montage = () => {
  const data = useStaticQuery(query);

  return (
    <StyledBackgroundMontage id="montage">
      <StyledMontage className="container montage">
        <Headline align="right" className="montage__headline">
          Usługi montażowe
        </Headline>
        <StyledDescription className="montage__description">
          <GatsbyImage
            fluid={data.file.childImageSharp.fluid}
            alt="montaż"
            className="montage__description__image"
          />
          <p className="montage__description__paragraph">
            Poprawny montaż oraz właściwe położenie okien i drzwi jest gwarancją
            bezproblemowego funkcjonowania stolarki na długie lata. Nasza firma
            posiada wieloletnie doświadczenie w tej usłudze. Dostosowujemy się
            do potrzeb konsumenta oraz gwarantujemy profesjonalne podeście do
            sprzętu jak i klienta. Do naszych usług należy montaż:
            <ul>
              <li>okien</li>
              <li>drzwi</li>
              <li>rolet</li>
            </ul>
          </p>
        </StyledDescription>
        <Tiles />
      </StyledMontage>
    </StyledBackgroundMontage>
  );
};

export default Montage;
