import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledBigNav = styled.nav`
  #Path_2 {
    fill: ${({ theme }) => theme.colors.additional};
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    width: 100%;

    @media ${device.tablet} {
      width: 415px;
    }
  }

  .big__nav {
    display: none;

    & svg {
      & path {
        stroke: ${({ theme }) => theme.colors.additional};
      }
    }

    @media ${device.tablet} {
      display: flex;
    }
  }
`;

export default StyledBigNav;
