import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import Nav from '../BigNav/Nav/Nav';
import StyledHamburgerMenu from './HamburgerMenu.styled';

const HamburgerMenu = ({ isMenuActive, setMenuActive }) => {
  const menu = useRef(null);

  const listener = e => {
    if (!menu.current || menu.current.contains(e.target)) {
      return;
    }

    setMenuActive(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  });

  return (
    <StyledHamburgerMenu isMenuActive={isMenuActive} useRef={menu}>
      <Nav setMenuActive={setMenuActive} />
    </StyledHamburgerMenu>
  );
};

HamburgerMenu.propTypes = {
  isMenuActive: propTypes.bool.isRequired,
  setMenuActive: propTypes.func.isRequired,
};

export default HamburgerMenu;
