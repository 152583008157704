import React from 'react';
import CookieConsent from 'react-cookie-consent';
import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Header from '../components/Header/Header';
import Montage from '../components/Montage/Montage';
import Offer from '../components/Offer/Offer';
import Producers from '../components/Producers/Producers';
// import Realizations from '../components/Realizations/Realizations';
import Footer from '../components/Footer/Footer';
import SEO from '../components/seo';

const IndexPage = () => (
  <>
    <CookieConsent
      buttonText="ok"
      cookieName="cookie-consent"
      style={{
        background: '#000',
        width: '325px',
        position: 'fixed',
        left: '15px',
        bottom: '15px !important',
        transform: 'translateY(-15px)',
        marginTop: '15px',
        height: '180px',
        fontSize: '18px',
      }}
      buttonStyle={{
        background: '#d7b1e6',
        color: '#000',
        fontSize: '15px',
        width: '100px',
      }}
      expires={150}
    >
      Korzystając ze strony, wyrażasz zgodę na używanie plików cookies.
    </CookieConsent>
    <SEO
      lang="pl"
      title="Home"
      description="Okna, drzwi, rolety do Twojego domu. Montaż i fachowe doradztwo. Scholbud pomoże Ci w tym wszystkim. Bydgoszcz, Rynarzewo, Szubin, Łochowo, Białe Błota."
    />
    <Header />
    <About />
    <Producers />
    <Offer />
    <Montage />
    {/* <Realizations /> */}
    <Contact />
    <Footer />
  </>
);

export default IndexPage;
