import styled from 'styled-components';

export const StyledAbout = styled.div`
  padding: 116px 0 155px;
`;

export const StyledAboutBackground = styled.section`
  width: 100vw;
  background: ${({ theme }) => theme.colors.secondary};
`;
