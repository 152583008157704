import React from 'react';
import styled from 'styled-components';
import Tile from './Tile';
import CheckIcon from '../../../assets/icons/check.svg';
import TargetIcon from '../../../assets/icons/target.svg';
import PersonIcon from '../../../assets/icons/person.svg';
import { device } from '../../../style/theme';

const StyledTileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  & > div {
    width: auto;

    @media ${device.tablet} {
      width: 33%;
    }
  }

  & > div:nth-child(2) {
    transform: scale(1.07);
    z-index: 1;
    outline: solid 5px ${({ theme }) => theme.colors.secondary};

    @media ${device.tablet} {
      transform: scale(1.03);
    }
  }
`;

const TileWrapper = () => (
  <StyledTileWrapper>
    <Tile>
      <TargetIcon className="tile__icon" />
      <h3 className="tile__head">Nasze cele</h3>
      <p className="tile__text">
        Naszym głównym celem jest sprostać wymaganiom klientów oraz inwestorów.
        Każdego klienta traktujemy bardzo poważnie i dopasowujemy najlepszą
        ofertę do Państwa indywidualnych życzeń.
      </p>
    </Tile>
    <Tile>
      <PersonIcon className="tile__icon" />
      <h3 className="tile__head">O nas</h3>
      <p className="tile__text">
        Każdy nasz produkt doskonale sprawdza się w codziennym użytkowaniu w
        domach mieszkalnych, jak również obiektach przemysłowych i użyteczności
        publicznej.
      </p>
    </Tile>
    <Tile>
      <CheckIcon className="tile__icon" />
      <h3 className="tile__head">Gwarantowane usługi</h3>
      <p className="tile__text">
        Zajmujemy się sprzedażą okien, drzwi, parapetów oraz rolet. W tym celu
        współpracujemy z wieloma producentami. W naszej ofercie jest również
        usługa montoażowa jak i wycena całej usługi.
      </p>
    </Tile>
  </StyledTileWrapper>
);

export default TileWrapper;
