import React from 'react';
import propTypes from 'prop-types';
import { StyledNavLink, StyledNavHref } from './NavLink.styled';
import DropdownIcon from '../../../../../assets/icons/dropdown-arrow.svg';

const NavLink = ({ children, isDropdown, href, onClick }) => (
  <StyledNavLink>
    {isDropdown ? (
      <>
        <StyledNavHref>{children}</StyledNavHref>
        <ul className="dropdown-list">
          <StyledNavLink>
            <StyledNavHref onClick={onClick} href="#windows">
              okna PCV
            </StyledNavHref>
          </StyledNavLink>
          <StyledNavLink>
            <StyledNavHref onClick={onClick} href="#doors">
              drzwi
            </StyledNavHref>
          </StyledNavLink>
          <StyledNavLink>
            <StyledNavHref onClick={onClick} href="#blinds">
              rolety
            </StyledNavHref>
          </StyledNavLink>
        </ul>
        <DropdownIcon />
      </>
    ) : (
      <StyledNavHref onClick={onClick} href={href}>
        {children}
      </StyledNavHref>
    )}
  </StyledNavLink>
);

NavLink.propTypes = {
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node)]).isRequired,
  isDropdown: propTypes.bool,
  href: propTypes.string,
  onClick: propTypes.func,
};

NavLink.defaultProps = {
  isDropdown: false,
  href: null,
  onClick: null,
};

export default NavLink;
