import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import StyledNav from './Nav.styled';
import NavLink from './NavLink/NavLink';

const Nav = ({ setMenuActive }) => {
  useEffect(() => {}, []);

  return (
    <StyledNav className="big__nav">
      <NavLink
        onClick={setMenuActive ? () => setMenuActive(false) : null}
        href="#about"
      >
        firma
      </NavLink>
      <NavLink
        onClick={setMenuActive ? () => setMenuActive(false) : null}
        isDropdown
      >
        oferta
      </NavLink>
      <NavLink
        onClick={setMenuActive ? () => setMenuActive(false) : null}
        href="#montage"
      >
        montaż
      </NavLink>
      <NavLink
        onClick={setMenuActive ? () => setMenuActive(false) : null}
        href="#contact"
      >
        kontakt
      </NavLink>
    </StyledNav>
  );
};

Nav.propTypes = {
  setMenuActive: propTypes.func,
};

Nav.defaultProps = {
  setMenuActive: null,
};

export default Nav;
