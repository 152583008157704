import styled from 'styled-components';

const StyledNav = styled.ul`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0;
  display: flex;
  font-size: 1.9rem;
  font-weight: 400;
  text-transform: uppercase;

  & .dropdown-list {
    position: absolute;
    color: ${({ theme }) => theme.colors.secondary};
    padding: 10px 5px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 100ms ease-in-out;
    font-size: 2.2rem;
    background: ${({ theme }) => theme.colors.primary};
    z-index: 1;
    width: 150px;
    left: -37.5px;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & li {
      list-style-type: square;
    }
  }

  & > li:not(:first-child) {
    margin-left: 20px;
  }

  & li:hover {
    text-decoration: underline;

    .dropdown-list {
      visibility: visible;
      opacity: 1;
    }

    & svg {
      transform: rotate(90deg);
    }
  }
`;

export default StyledNav;
