import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledTile = styled.div`
  background: ${({ theme }) => theme.colors.additional};
  padding: 50px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px;

  color: ${({ theme }) => theme.colors.primary};
  & svg path,
  g {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  .tile {
    &__head {
      font-size: 2.1rem;
      font-weight: 500;
    }

    &__text {
      font-size: 1.7rem;
      font-weight: 300;
      text-align: center;
    }
  }
`;

const Tile = ({ children }) => <StyledTile>{children}</StyledTile>;

Tile.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default Tile;
