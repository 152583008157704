import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledHero = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px;
  background: ${({ theme }) => theme.colors.primary};

  .hero-image {
    width: 283px;
    height: 286px;
    margin-bottom: 30px;
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin: 55px 0;

    .hero-image {
      width: 548px;
      height: 554px;
      margin-bottom: 0;
    }
  }
`;

export default StyledHero;
