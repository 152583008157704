import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledTiles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 100px 0;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export default StyledTiles;
