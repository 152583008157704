import React from 'react';
import Tile from './Tile/Tile';
import StyledTiles from './Tiles.styled';
import SmileIcon from '../../../assets/icons/smile.svg';
import HandIcon from '../../../assets/icons/hand.svg';
import HammerIcon from '../../../assets/icons/hammer.svg';

const Tiles = () => (
  <StyledTiles>
    <Tile
      withCheck
      text="Rzetelne doradztwo 
oraz korzystna wycena"
    >
      <SmileIcon className="tile__icon" />
    </Tile>
    <Tile
      withCheck
      text="Gwarancja jakości 
wykonanych usług"
    >
      <HammerIcon className="tile__icon" />
    </Tile>
    <Tile
      text="Rzetelna i fachowa
pomoc na etapie
realizacji "
    >
      <HandIcon className="tile__icon" />
    </Tile>
  </StyledTiles>
);

export default Tiles;
