import React, { useState } from 'react';
import styled from 'styled-components';
import BigNav from './BigNav/BigNav';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import StyledHeader from './Header.styled';
import Hero from './Hero/Hero';
import SmallNav from './SmallNav/SmallNav';

const StyledHeaderBackground = styled.header`
  width: 100vw;
  background: ${({ theme }) => theme.colors.primary};
`;

const Header = () => {
  const [isMenuActive, setMenuActive] = useState(false);

  return (
    <StyledHeaderBackground>
      <StyledHeader className="container">
        <SmallNav isMenuActive={isMenuActive} setMenuActive={setMenuActive} />
        <BigNav />
        <Hero />
        <HamburgerMenu
          isMenuActive={isMenuActive}
          setMenuActive={setMenuActive}
        />
      </StyledHeader>
    </StyledHeaderBackground>
  );
};

export default Header;
