import styled from 'styled-components';

export const StyledBackgroundContact = styled.section`
  background: #262626;
  padding-top: 20px;

  .contact {
    &__headline {
      color: ${({ theme }) => theme.colors.secondary};
      padding-bottom: 85px;
    }
  }
`;

export const StyledContact = styled.div``;
