import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import OfferItem from '../OfferItem/OfferItem';

const StyledWindowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: 150px 0;

  & h4 {
    margin: 20px auto;
    font-size: 1.8rem;
  }

  & .item {
    display: flex;
    flex-direction: column;
  }

  & > * {
    width: 100%;
    @media (min-width: 1024px) {
      width: 80%;
    }

    margin: 0 auto;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const query = graphql`
  {
    allFile(
      filter: {
        name: {
          regex: "/^door$|^blind$|^window-1$|^dab_naturalny$|^iglo_energy$/"
        }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;

const Wrapper = () => {
  const data = useStaticQuery(query);

  const findImage = name =>
    data?.allFile?.edges.find(item => item.node.name === name).node
      .childImageSharp.fluid;

  return (
    <>
      <OfferItem
        fluid={findImage('door')}
        alt="drzwi"
        position="left"
        id="doors"
      >
        <h4 className="offer-item__description__headline">Drzwi</h4>
        <div className="offer-item__description__section">
          <h5 className="offer-item__description__section__headline">
            Zewnętrzne lub wewnętrzne
          </h5>
          <span className="offer-item__add">
            <span className="offer-item__underlined">Drzwi zewnętrzne:</span>{' '}
            KMT Stal, WIKĘD, Doorsy <br />
            <span className="offer-item__underlined">
              Drzwi wewnętrzne:
            </span>{' '}
            ERKADOO, DRE, POL-SKONE
          </span>
          <p className="offer-item__description__section__paragraph">
            Wybierając okna naszych producentów mają Państwo pewność
            bezpieczeństwa i gwarancję na długie lata. Do produkcji drzwi
            używane są tylko sprawdzone technologie oraz najlepsze materiały, co
            w połączeniu z najnowszymi rozwiązaniami i współczesnymi trendami
            sprawia, że nasza oferta pozwala wpasować się w gust i potrzeby
            najbardziej wymagających klientów.
            <br />
            <br />
            Zobacz katalog drzwi od naszych producentów.
          </p>
        </div>
      </OfferItem>
      <OfferItem
        fluid={findImage('window-1')}
        alt="onko"
        position="right"
        id="windows"
      >
        <h4 className="offer-item__description__headline">Okna PCV</h4>
        <div className="offer-item__description__section">
          <h5 className="offer-item__description__section__headline">
            5-komorowe oraz 7-komorowe
          </h5>
          <p className="offer-item__description__section__paragraph">
            5-komorowe profile ramy i skrzydła wykonane wyłącznie z materiału
            pierwotnego w klasie A, o głębokości zabudowy 70 mm <br />
            <br />
            7-komorowe profile ramy i skrzydła wykonane wyłącznie z materiału
            pierwotnego, w klasie A, o głębokości zabudowy 82 mm
            <br />
            <br /> Oferta Drutex dostępna tutaj
          </p>
        </div>
      </OfferItem>
      <StyledWindowWrapper>
        <div className="item">
          <GatsbyImage fluid={findImage('dab_naturalny')} />
          <h4>5-komorowe</h4>
        </div>
        <div className="item">
          <GatsbyImage fluid={findImage('iglo_energy')} />
          <h4>7-komorowe</h4>
        </div>
      </StyledWindowWrapper>
      <OfferItem
        fluid={findImage('blind')}
        alt="roleta"
        position="left"
        id="blinds"
        bigger
      >
        <h4 className="offer-item__description__headline">Rolety</h4>
        <div className="offer-item__description__section">
          <h5 className="offer-item__description__section__headline">
            Rolety zewnętrzne
          </h5>
          <p className="offer-item__description__section__paragraph">
            Nasze rolety spełniają niejedną funkcję. Dzięki nowoczesnej
            technologii i rozwiązaniom klienci decydujcy się na nasze rolety
            mogą liczyć na wysokiej klasy ochronę przed promieniowaniem
            slonecznym. Zapewnia to chłodniejszą temperaturę w ciepłe i upalne
            dni, a zarazem utrzymuje ciepłą temperaturę w zimowe wieczory.
            <br />
            <br />
            Pondadto, rolety naszych producentów zwiekszają bezpieczeństwo w
            domach i mieszkaniach.
            <br />
            <br />
            Dzięki wysokiemu współczynnikowi tłumienia dźwięku rolety zapewniają
            redukcję hałasu z zewnątrz.
          </p>
        </div>
      </OfferItem>
    </>
  );
};

export default Wrapper;
