import styled from 'styled-components';
import { device } from '../../style/theme';

export const StyledBackgroundMontage = styled.section`
  background: ${({ theme }) => theme.colors.secondary};
`;

export const StyledMontage = styled.div`
  .montage {
    &__headline {
      margin-bottom: 40px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      @media ${device.tablet} {
        flex-direction: row-reverse;
      }

      &__image {
        width: 100vw;
        left: 0;
        position: absolute !important;

        @media (min-width: 426px) {
          left: 0;
          width: 100%;
          position: relative !important;
        }

        @media ${device.tablet} {
          width: 512px;
          height: 342px;
          left: auto;
        }
      }

      &__paragraph {
        font-size: 1.8rem;
        padding: 40px 0;
        margin-top: 200px;

        @media (min-width: 375px) {
          margin-top: 280px;
        }

        @media (min-width: 425px) {
          margin-top: 320px;
        }

        @media (min-width: 426px) {
          margin-top: 0;
        }

        @media ${device.tablet} {
          width: 50%;
          padding: 0;
          margin: 0;
          padding-right: 40px;
        }
      }
    }
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
`;
