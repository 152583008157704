import styled from 'styled-components';

export const StyledNavLink = styled.li`
  position: relative;
  display: flex;
  align-items: center;

  svg {
    width: 11px;
    height: 7px;
    left: 4px;
    position: relative;
    transition: transform 200ms ease-in-out;

    & path {
      stroke: ${({ theme }) => theme.colors.additional};
    }
  }
`;
export const StyledNavHref = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
`;
