import React from 'react';
import StyledBigNav from './BigNav.styled';
import Logo from '../../../assets/icons/Logo.svg';
import Nav from './Nav/Nav';

const BigNav = () => (
  <StyledBigNav>
    <Logo />
    <Nav />
  </StyledBigNav>
);

export default BigNav;
