import React from 'react';
import Headline from '../Headline/Headline';
import { StyledAbout, StyledAboutBackground } from './About.styled';
import TileWrapper from './TileWrapper/TileWrapper';

const About = () => (
  <StyledAboutBackground id="about">
    <StyledAbout className="container">
      <Headline>O nas</Headline>
      <TileWrapper />
    </StyledAbout>
  </StyledAboutBackground>
);

export default About;
