import React from 'react';
import styled from 'styled-components';

const StyledInformations = styled.div`
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.secondary};

  .highlighted {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.additional};
  }

  .lesser {
    font-size: 1.8rem;
  }
`;

const Informations = () => (
  <StyledInformations className="contact__informations">
    <span className="lesser">
      <span>
        P.P.H.U. SCHOL-BUD
        <br />
      </span>
      <span>Krzysztof Pietrzak</span>
    </span>
    <br />
    <span>
      <span className="highlighted">Godziny otwarcia</span>
      <br />
      <span className="lesser">Pon. - Pt. 8:00 - 16:00</span>
    </span>
    <br />
    <span>
      <span className="highlighted">NIP</span>:{' '}
      <span className="lesser">5571294210</span>
    </span>
    <span>
      <span className="highlighted">REGON</span>:{' '}
      <span className="lesser">639647735</span>
    </span>

    <br />
    <span>
      <span className="highlighted">Telefon</span>:{' '}
      <span className="lesser"> 52 30 30 270, 606 333 012 </span>
    </span>
    <span>
      <span className="highlighted">E-mail</span>:{' '}
      <span className="lesser"> scholbud@wp.pl </span>
    </span>
    <br />
    <span>
      <span className="highlighted">ADRES</span>:
    </span>
    <span className="lesser">Januszkowo 43B 88-400 </span>
    <br />
    <span className="lesser">
      <span>
        ul. Notecka 2 <br />
      </span>
      <span>89-200 Rynarzewo</span>
    </span>
  </StyledInformations>
);

export default Informations;
