import React from 'react';
import styled from 'styled-components';
import Phone from '../../../assets/icons/phone.svg';

const StyledPhoneInfo = styled.span`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.secondary};

  & > * {
    margin-left: 10px;
  }

  .phone-icon {
    opacity: 1;
    width: 21px;
    height: 21px;

    & path {
      fill: ${({ theme }) => theme.colors.additional} !important;
    }
  }

  .phone-number {
    font-size: 1.6rem;
    font-weight: 700;
    border: solid 2px ${({ theme }) => theme.colors.additional};
    border-radius: 11px;
    padding: 0 15px;
  }
`;

const PhoneInfo = () => (
  <StyledPhoneInfo>
    Zadzwoń <Phone className="phone-icon" />
    <span className="phone-number">606 333 012</span>
  </StyledPhoneInfo>
);

export default PhoneInfo;
