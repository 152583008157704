import React from 'react';
import Description from './Description/Description';
import { StyledBackgroundProducers, StyledProducers } from './Procuders.styled';
import Wrapper from './Wrapper/Wrapper';

const Producers = () => (
  <StyledBackgroundProducers>
    <StyledProducers className="container">
      <Description />
      <Wrapper />
    </StyledProducers>
  </StyledBackgroundProducers>
);

export default Producers;
