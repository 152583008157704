import React from 'react';
import { StyledBackgroundOffer, StyledOffer } from './Offer.styled';
import Headline from '../Headline/Headline';
import Wrapper from './Wrapper/Wrapper';

const Offer = () => (
  <StyledBackgroundOffer id="offer">
    <StyledOffer className="container">
      <Headline className="offer__headline">Oferta</Headline>
      <Wrapper />
    </StyledOffer>
  </StyledBackgroundOffer>
);

export default Offer;
