import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../style/theme';

const StyledHeroText = styled.div`
  width: 100%;

  @media ${device.tablet} {
    width: 330px;
  }

  .hero {
    &__text {
      font-size: 5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.secondary};
    }

    &__button {
      background: ${({ theme }) => theme.colors.additional};
      font-size: 2rem;
      font-weight: 500;
      padding: 13px 0;
      text-align: center;
      width: 100%;
      margin-top: 35px;
      color: #000;

      @media ${device.tablet} {
        width: 366px;
        margin-top: 11px;
      }
    }
  }
`;

const HeroText = () => (
  <StyledHeroText>
    <span className="hero__text">Sprzedawca okien, drzwi oraz rolet</span>
    <a href="#offer">
      <button className="hero__button" type="button">
        Zobacz naszą ofertę
      </button>
    </a>
  </StyledHeroText>
);

export default HeroText;
