import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin: 70px 0;

  @media ${device.tablet} {
    flex-direction: ${({ position }) =>
      position === 'left' ? 'row' : 'row-reverse'};
  }

  .offer-item {
    &__add {
      font-size: 1.7rem;
      font-weight: bold;
      margin-top: 10px;
      display: block;
    }

    &__underlined {
      text-decoration: underline;
    }

    &__image {
      width: 50%;

      /* &.bigger {
        width: 100%;
      } */

      @media ${device.tablet} {
        width: 25%;

        /* &.bigger {
          width: 50%;
        } */
      }
    }

    &__description {
      display: flex;
      flex-direction: column;

      @media ${device.tablet} {
        width: 40%;
      }

      &__headline {
        font-size: 4.6rem;
        margin: 30px 0 10px;

        border: solid 1px black;
        width: fit-content;
        padding: 10px 30px;

        align-self: ${({ position }) =>
          position === 'right' ? 'flex-end' : null};

        transform: ${({ position }) =>
          position === 'right' ? 'translate(10px, 20px)' : null};

        transform: ${({ position }) =>
          position === 'left' ? 'translate(-10px, 20px)' : null};
      }

      &__section {
        border: solid 1px black;
        padding: 30px 25px;

        &__headline {
          font-size: 2.9rem;
          margin: 0;
        }
        &__paragraph {
          font-size: 1.8rem;
        }
      }
    }
  }
`;

export default StyledItem;
