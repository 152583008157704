import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { device } from '../../style/theme';

const StyledHeadline = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 5rem;
  font-weight: 500;
  /* margin: 0 auto; */
  width: fit-content;

  margin: ${({ align }) => (align === 'center' ? '0 auto' : null)};
  margin: ${({ align }) => (align === 'left' ? '0 auto 0 0' : null)};
  margin: ${({ align }) => (align === 'right' ? '0 0 0 auto' : null)};

  @media ${device.tablet} {
    font-size: 6.1rem;
  }
`;

const Headline = ({ children, className, align }) => (
  <StyledHeadline className={className} align={align}>
    {children}
  </StyledHeadline>
);

Headline.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  className: propTypes.string,
  align: propTypes.string,
};

Headline.defaultProps = {
  className: null,
  align: 'center',
};

export default Headline;
