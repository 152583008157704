import GatsbyImage from 'gatsby-image';
import React from 'react';
import propTypes from 'prop-types';
import StyledItem from './OfferItem.styled';

const OfferItem = ({ position, alt, fluid, children, id, bigger = false }) => (
  <StyledItem className="offer-item" position={position} id={id}>
    <GatsbyImage
      className={`offer-item__image${bigger ? ' bigger' : ''}`}
      alt={alt}
      fluid={fluid}
    />
    <div className="offer-item__description">{children}</div>
  </StyledItem>
);

OfferItem.propTypes = {
  position: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
  fluid: propTypes.shape(
    propTypes.oneOfType([propTypes.shape, propTypes.string])
  ).isRequired,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  id: propTypes.string.isRequired,
  bigger: propTypes.bool,
};

OfferItem.defaultProps = {
  bigger: propTypes.boolean,
};

export default OfferItem;
