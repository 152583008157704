import styled from 'styled-components';
import { device } from '../../../style/theme';

const StyledHamburgerButton = styled.button`
  width: 34px;
  height: 16px;
  display: inline;
  position: relative;
  z-index: 5;

  .header__menu-button__divs {
    & > * {
      position: relative;
      width: 34px;
      height: 4px;
      background: ${({ theme }) => theme.colors.additional};

      transition: transform 300ms ease-in-out, opacity 300ms ease-in-out,
        height 200ms linear, width 200ms linear;
      opacity: 1;
      transform-origin: left;
    }

    & > div:nth-child(1) {
      top: -4px;

      ${({ isMenuActive }) =>
        isMenuActive &&
        `
        transform: rotate(45deg);
        height: 5px;
        width: 24px;
      `};
    }

    & > div:nth-child(2) {
      ${({ isMenuActive }) =>
        isMenuActive &&
        `
        opacity: 0;
        transform: translateX(-40px);
      `};
    }

    & > div:nth-child(3) {
      top: 4px;

      ${({ isMenuActive }) =>
        isMenuActive &&
        `
        transform: rotate(-45deg);
        height: 5px;
        width: 24px;
      `};
    }

    /* ${({ isMenuActive }) =>
      isMenuActive &&
      `
        background: transparent;
      `}; */
  }

  @media ${device.tablet} {
    display: none;
  }
`;

export default StyledHamburgerButton;
