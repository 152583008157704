import styled from 'styled-components';

const StyledHamburgerMenu = styled.div`
  width: 80vw;
  height: 100vh;
  position: fixed;
  z-index: 4;
  background: ${({ theme }) => theme.colors.primary};
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1),
    box-shadow 200ms linear;

  transform: ${({ isMenuActive }) =>
    isMenuActive ? 'translateX(0)' : 'translateX(-100%)'};

  box-shadow: ${({ isMenuActive }) =>
    isMenuActive ? '14px -4px 50px 8px rgba(0, 0, 0, 0.75)' : null};

  & > ul {
    flex-direction: column;
    align-items: center;

    & > li {
      margin-left: 0 !important;
    }

    & > li:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

export default StyledHamburgerMenu;
